/** @jsx jsx */
import { AspectRatio, Box, Flex, jsx } from 'theme-ui';
import ReactPlayer from 'react-player';
import { useCallback, useRef, useState, useEffect } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useInView } from 'react-intersection-observer';

import { VideoProps } from './types';
import VideoControls from './Controls';
import VideoPoster from './Poster';
import { usePlayingValue, useMutedValue, useProgressValue } from './hooks';
import PlayIcon from './PlayIcon';

const Video: React.FC<VideoProps> = ({
  image,
  url,
  small,
  autoPlay = false,
  noControls,
}) => {
  const [areControlsVisible, setAreControlsVisible] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const { ref, inView } = useInView();
  const { isPlaying, handlePlayPause, startPlaying } = usePlayingValue(
    autoPlay,
  );
  const { isMuted, handleMute } = useMutedValue(autoPlay);
  const playerRef = useRef<ReactPlayer | null>(null);
  const loaderRef = useRef<ReactPlayer | null>(null);
  const {
    videoProgress,
    handleVideoProgress,
    setVideoProgress,
  } = useProgressValue(playerRef.current);

  const handleOnProgress = useCallback(
    ({ played }: { played: number }) => {
      setVideoProgress(played);
    },
    [setVideoProgress],
  );

  const handleOnStart = useCallback(() => {
    if (!areControlsVisible) {
      setAreControlsVisible(true);
    }
  }, [areControlsVisible]);

  const onLoad = useCallback(() => {
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    if (inView && loaderRef.current) {
      loaderRef.current?.handleClickPreview();
    }
  }, [inView]);

  return (
    <AspectRatio ratio={194 / 109}>
      <Box variant="video.wrapper" {...{ ref }}>
        <Box
          variant="video.player"
          className={areControlsVisible ? 'is-visible' : ''}
        >
          {isLoaded ? (
            <ReactPlayer
              key="loaded-video"
              progressInterval={150}
              ref={playerRef}
              height="100%"
              width="100%"
              controls={false}
              playing={isPlaying}
              muted={isMuted}
              volume={1}
              loop={autoPlay}
              playsinline
              playIcon={<PlayIcon {...{ small }} />}
              onProgress={handleOnProgress}
              onStart={handleOnStart}
              {...{ url }}
            />
          ) : (
            <ReactPlayer
              ref={loaderRef}
              key="loader-video"
              height="100%"
              light={image?.src}
              onReady={onLoad}
              width="100%"
              controls={false}
              playIcon={<PlayIcon {...{ small }} />}
              {...{ url }}
            />
          )}
        </Box>
        {!!image && (
          <VideoPoster onClick={startPlaying} {...{ image }}>
            {!autoPlay && <PlayIcon {...{ small }} />}
          </VideoPoster>
        )}
        {!noControls && (
          <Flex
            variant="video.controlsWrapper"
            className={`controls ${!isPlaying ? 'is-paused' : ''} ${
              areControlsVisible ? 'is-visible' : ''
            }`}
          >
            <VideoControls
              {...{
                handlePlayPause,
                isPlaying,
                isMuted,
                handleMute,
                videoProgress,
                handleVideoProgress,
              }}
            />
          </Flex>
        )}
      </Box>
    </AspectRatio>
  );
};

export default Video;
