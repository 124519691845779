/** @jsx jsx */
import { AspectRatio, Box, Text, jsx, Flex } from 'theme-ui';
import TransitionLink from 'gatsby-plugin-transition-link';
import { Link } from 'gatsby';
import Image from '../../atoms/Image';
import { ThumbnailTileProps } from './types';
import { IconEye } from '../../../assets/svg';
import Video from '../Video';
import { entryAnimation, exitAnimation } from './utils';

const ThumbnailTile: React.FC<ThumbnailTileProps> = ({
  to,
  category,
  title,
  image,
  video,
  dark,
  small,
  notAnimated,
}) => {
  const content = (
    <AspectRatio ratio={194 / 109}>
      <Box variant="thumbnailTile.media" className="thumbnail-tile-media">
        <AspectRatio ratio={194 / 109}>
          {video?.url ? (
            <Video autoPlay noControls {...video} />
          ) : (
              <Image {...image} />
            )}
        </AspectRatio>
      </Box>
      <Flex variant="thumbnailTile.overlay" className="overlay">
        <IconEye sx={{ variant: 'thumbnailTile.overlay.icon' }} />
      </Flex>
      <Box
        variant={small ? 'thumbnailTile.contentSmall' : 'thumbnailTile.content'}
      >
        <Text variant="tags" mb="0.5rem" as="h4">
          {category}
        </Text>
        <Text
          variant={small ? 'h4' : 'h2'}
          as="h3"
          sx={{
            display: small
              ? ['none', 'none', 'none', 'block']
              : ['none', 'block', 'block'],
          }}
        >
          {title}
        </Text>
      </Box>
    </AspectRatio>
  );

  return (
    <Box
      sx={{
        variant: dark ? 'thumbnailTile.wrapperDark' : 'thumbnailTile.wrapper',
      }}
    >
      {notAnimated ? (
        <Link
          sx={{
            variant: 'thumbnailTile.link',
          }}
          {...{ to }}
        >
          {content}
        </Link>
      ) : (
          <TransitionLink
            sx={{
              variant: 'thumbnailTile.link',
            }}
            {...{ to }}
            exit={{
              trigger: ({ node }) => exitAnimation(node),
              length: 1.5,
            }}
            entry={{
              trigger: ({ node }) => entryAnimation(node),
              delay: 0.3,
              length: 2,
              zIndex: 2,
            }}
          >
            {content}
          </TransitionLink>
        )}
    </Box>
  );
};

export default ThumbnailTile;
