export const exitAnimation = (element: HTMLElement): void => {
  const nav = document.querySelector('header');
  const page = element.querySelector('.page-wrapper');

  if (!nav || !page) return;

  const pageOffset = -Math.round(window.scrollY - nav.offsetHeight);

  page.classList.add('is-exiting');
  page.style.top = `${pageOffset}px`;
  document.body.classList.add('is-animating');
};

export const entryAnimation = (element: HTMLElement): void => {
  const html = document.documentElement;

  const caseStudyHeroWrapper = element.querySelector(
    '.case-study-hero-wrapper',
  );
  const nav = document.querySelector('header');
  const page = element.querySelector('.page-wrapper');

  if (!caseStudyHeroWrapper || !nav || !page) return;

  const headerHeight = nav.offsetHeight;

  const scrollOnStart = () => {
    window.scroll(0, headerHeight);
  }

  page.addEventListener('animationstart', scrollOnStart);

  page.classList.add('is-entering-start');
  html.style.overflow = 'hidden';

  setTimeout(() => {
    page.classList.remove('is-entering-start');
    page.classList.add('is-entering');
    caseStudyHeroWrapper.classList.add('is-hidden');
  });
  page.addEventListener('animationend', () => {
    page.classList.add('is-scrolled');
    page.removeEventListener('animationstart', scrollOnStart);
  });

  caseStudyHeroWrapper.addEventListener('animationend', () => {
    html.style.overflow = '';
    page.classList.remove('is-entering');
    page.classList.remove('is-scrolled');
    document.body.classList.remove('is-animating');
    caseStudyHeroWrapper.classList.remove('is-hidden');
  });
};
