import { useCallback, useState } from 'react';
import { Range } from 'react-input-range';
import ReactPlayer from 'react-player';

interface UsePlayingValueReturnType {
  isPlaying: boolean;
  handlePlayPause: (value?: boolean) => void;
  startPlaying: () => void;
}

export const usePlayingValue = (
  playing: boolean,
): UsePlayingValueReturnType => {
  const [isPlaying, setIsPlaying] = useState(playing);

  const handlePlayPause = useCallback((value?: boolean) => {
    setIsPlaying(value === undefined ? (val) => !val : value);
  }, []);

  const startPlaying = useCallback(() => {
    setIsPlaying(true);
  }, []);

  return {
    isPlaying,
    handlePlayPause,
    startPlaying,
  };
};

interface UseMutedValueReturnType {
  isMuted: boolean;
  handleMute: () => void;
}

export const useMutedValue = (muted: boolean): UseMutedValueReturnType => {
  const [isMuted, setIsMuted] = useState(muted);

  const handleMute = useCallback(() => {
    setIsMuted((val) => !val);
  }, []);

  return {
    isMuted,
    handleMute,
  };
};

interface UseProgressValueReturnType {
  videoProgress: number;
  handleVideoProgress: (value: number | Range) => void;
  setVideoProgress: (value: number) => void;
}

export const useProgressValue = (
  player: ReactPlayer | null,
): UseProgressValueReturnType => {
  const [videoProgress, setVideoProgress] = useState<number>(0);

  const handleVideoProgress = useCallback(
    (value: number | Range) => {
      if (typeof value === 'number' && player) {
        setVideoProgress(value);
        player.seekTo(value, 'fraction');
      }
    },
    [player],
  );

  return {
    videoProgress,
    setVideoProgress,
    handleVideoProgress,
  };
};
