/** @jsx jsx */
import { jsx, Button, Flex, Label } from 'theme-ui';
import InputRange from 'react-input-range';
import {
  IconPause,
  IconPlay,
  IconSoundOff,
  IconSoundOn,
} from '../../../../assets/svg';
import { VideoControlsProps } from './types';

const VideoControls: React.FC<VideoControlsProps> = ({
  isPlaying,
  isMuted,
  videoProgress,
  handlePlayPause,
  handleMute,
  handleVideoProgress,
}) => (
  <Flex variant="video.controls.wrapper">
    <Flex variant="video.controls.button">
      <Button type="button" variant="video" onClick={() => handlePlayPause()}>
        {isPlaying ? <IconPause /> : <IconPlay />}
      </Button>
    </Flex>
    <Flex variant="video.controls.track">
      <InputRange
        value={videoProgress}
        minValue={0}
        maxValue={1}
        step={0.01}
        onChange={handleVideoProgress}
        onChangeStart={() => handlePlayPause(false)}
        onChangeComplete={() => handlePlayPause(true)}
      />
    </Flex>
    <Flex variant="video.controls.button">
      <Label variant="video.controls.checkbox">
        <input
          className="input"
          type="checkbox"
          checked={isMuted}
          onChange={handleMute}
        />
        {isMuted ? (
          <IconSoundOff sx={{ variant: 'video.controls.checkbox.icon' }} />
        ) : (
            <IconSoundOn sx={{ variant: 'video.controls.checkbox.icon' }} />
          )}
      </Label>
    </Flex>
  </Flex>
);

export default VideoControls;
