/** @jsx jsx */
import { jsx, Flex } from 'theme-ui';
import Image from '../../../atoms/Image';
import { VideoPosterProps } from './types';

const Poster: React.FC<VideoPosterProps> = ({ image, onClick, children }) => (
  <Flex variant="video.poster.wrapper" {...{ onClick }}>
    <Image {...image} alt="Video poster, click to play" />
    <Flex variant="video.poster.content">{children}</Flex>
  </Flex>
);

export default Poster;
