/** @jsx jsx */
import React from 'react'
import { Flex, jsx } from 'theme-ui';

import { IconChevronRight } from '../../../../assets/svg';

import { PlayIconProps } from './types';

const PlayIcon: React.FC<PlayIconProps> = ({ small }) => (
  <Flex
    variant={small ? 'video.playSmall' : 'video.play'}
    className="play"
  >
    <IconChevronRight
      sx={{
        variant: small ? 'video.playSmall.icon' : 'video.play.icon',
      }}
    />
  </Flex>
)

export default PlayIcon
